<template>
  <a-row type="flex" :gutter="16" style="flex-flow:unset;">
    <a-col flex="200px">
      <a-card>
        <template #title>
          <a-space>
            <a-button type="primary" small="small" @click="updateData">更新数据</a-button>
            <a-button type="link" small="small" @click="exportExcel" :disabled="excelDisabled">导出</a-button>
          </a-space>
        </template>
        <a-tree :treeData="treeList" :loadData="asynLoadData" :replaceFields="{title:'nodeName'}" @select="treeSelect" showLine blockNode />
      </a-card>
    </a-col>
    <a-col flex="auto">
      <ProfitTable v-if="isOrg" :dataSource="dataSource" />
      <ProfitDetail v-if="isPersonal" :dataSource="dataSource" />
    </a-col>
  </a-row>
</template>
<script>
import {
  saleProfitTree,
  saleProfitList,
  saleEmpProfitDetail,
  saleProfitExport,
  saleProfitListUpdate
} from '@/api/trans/finance/profit'
import { asynEmpOrgTree } from '@/api/system/employee'
import { reactive, ref, toRefs } from 'vue'
import { onMounted } from '@vue/runtime-core'
import { message, Modal } from 'ant-design-vue'
import ProfitTable from './ProfitTable.vue'
import ProfitDetail from './ProfitDetail.vue'

export default {
  setup () {
    const profitArchiveTable = ref()
    const state = reactive({
      excelDisabled: false,
      tableVisible: false,
      formVisible: false,
      treeList: [],
      archiveId: '',
      query: {},
      dataSource: [],
      isOrg: false,
      isPersonal: false
    })
    onMounted(() => {
      saleProfitTree({}).then(res => {
        if (res.code === 10000) {
          state.treeList = res.data
        }
      })
    })

    const asynLoadData = treeNode => {
      return new Promise(resolve => {
        if (treeNode.dataRef.children && treeNode.dataRef.children.length) {
          resolve()
          return
        }
        asynEmpOrgTree(treeNode.eventKey.split('-').length > 3 ? treeNode.dataRef.nodeId : '0', {}).then(res => {
          if (res.code === 10000) {
            console.log(res.data)
            treeNode.dataRef.children = res.data.map(item => ({ ...item, isLeaf: !item.nodeType.value }))
            resolve()
          }
        })
      })
    }

    const treeSelect = (key, el) => {
      const indexs = key.length && key[0].split('-')
      state.query.userId = undefined
      switch (indexs.length) {
        case 2:
          state.query.year = el.node.dataRef.nodeValue
          saleProfitList({ year: el.node.dataRef.nodeValue }).then(res => {
            if (res.code === 10000) {
              state.dataSource = res.data
              state.isOrg = true
              state.isPersonal = false
            }
          })
          break
        case 3:
          state.query.year = state.treeList[indexs[1]].nodeValue
          state.query.month = el.node.dataRef.nodeValue
          saleProfitList({ year: state.treeList[indexs[1]].nodeValue, month: el.node.dataRef.nodeValue }).then(res => {
            if (res.code === 10000) {
              state.dataSource = res.data
              state.isOrg = true
              state.isPersonal = false
            }
          })
          break
        default:
          if (indexs.length > 3) {
            if (el.node.dataRef.nodeType.value) {
              state.query.year = state.treeList[indexs[1]].nodeValue
              state.query.month = state.treeList[indexs[1]].children[indexs[2]].nodeValue
              state.query.orgId = el.node.dataRef.nodeId
              saleProfitList({ year: state.treeList[indexs[1]].nodeValue, month: state.treeList[indexs[1]].children[indexs[2]].nodeValue, orgId: el.node.dataRef.nodeId }).then(res => {
                if (res.code === 10000) {
                  state.dataSource = res.data
                  state.isOrg = true
                  state.isPersonal = false
                }
              })
            } else {
              state.query.year = state.treeList[indexs[1]].nodeValue
              state.query.month = state.treeList[indexs[1]].children[indexs[2]].nodeValue
              state.query.orgId = undefined
              state.query.userId = el.node.dataRef.nodeId
              saleEmpProfitDetail(el.node.dataRef.nodeId, { year: state.treeList[indexs[1]].nodeValue, month: state.treeList[indexs[1]].children[indexs[2]].nodeValue }).then(res => {
                if (res.code === 10000) {
                  state.dataSource = res.data
                  state.isOrg = false
                  state.isPersonal = true
                }
              })
            }
          } else {
          }
          break
      }
    }

    const updateData = () => {
      saleProfitListUpdate({
        ...state.query
      }).then(res => {
        if (res.code === 10000) {
          message.info('更新成功')
        }
      })
    }

    const exportExcel = () => {
      if (state.query.year || state.query.month) {
        state.excelDisabled = true
        saleProfitExport({ ...state.query }, '销售利润报表').then(res => {
          setTimeout(function () {
            state.excelDisabled = false
          }, 5000)
        })
      } else {
        Modal.warning({
          content: '请选择需要导出的年份或者月份'
        })
      }
    }

    return {
      profitArchiveTable,
      ...toRefs(state),
      asynLoadData,
      treeSelect,
      exportExcel,
      updateData
    }
  },
  components: {
    ProfitTable,
    ProfitDetail
  }
}
</script>
