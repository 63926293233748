<template>
  <a-table :dataSource="dataSource" :rowKey="record => record.orgId" :loading="loading" :pagination="false" bordered size="small" :scroll="{x: true}" :rowClassName="rowClass">
    <a-table-column width="100" title="机构名称" data-index="orgName" />
    <a-table-column width="100" title="接车台数" data-index="carNum" />
    <a-table-column width="100" title="订单量" data-index="orderNum" />
    <a-table-column width="100" title="总利润" data-index="profitTotal" />
  </a-table>
</template>
<script>
import { reactive, toRefs } from 'vue'
export default {
  props: { dataSource: { type: Array } },
  setup (props) {
    const state = reactive({
      loading: false,
      orgs: []
    })

    const exportExcel = () => {

    }

    const exportDetailExcel = () => {

    }

    const rowClass = (record, index) => {
      let rowClassName = ''
      if (index % 2 === 1) {
        rowClassName = 'table-striped'
      }
      if (record.status && !record.status.value) {
        rowClassName += ' not-archive'
      }
      return rowClassName
    }
    return {
      ...toRefs(state),
      rowClass,
      exportExcel,
      exportDetailExcel
    }
  }
}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}
.not-archive {
  color: #999;
}
</style>
