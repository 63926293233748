<template>
  <a-table :dataSource="dataSource" rowKey="id" :pagination="false" bordered size="small" :scroll="{x: true}" :rowClassName="rowClass">

    <a-table-column title="客户姓名" data-index="customerName" />
    <!-- <a-table-column  title="起始地" data-index="startAddress" />
    <a-table-column  title="目的地" data-index="endAddress" /> -->
    <a-table-column title="车型" data-index="model" />
    <a-table-column title="车架号" data-index="vinNo" />
    <!-- <a-table-column  title="取车费" data-index="placeInFee" />
    <a-table-column  title="送车费" data-index="takeOutFee" />
    <a-table-column  title="保险费" data-index="insurancePremium" /> -->
    <a-table-column title="保险成本" data-index="insuranceCost" />
    <a-table-column title="累计发车价" data-index="transportCost" />
    <a-table-column title="合同总金额" data-index="contractAmt" />
    <a-table-column title="税费" data-index="invoiceCost" />
    <a-table-column title="返款金额" data-index="refundFee" />
    <a-table-column title="其他费用" data-index="additionalFeeTotal" />
    <a-table-column title="毛利润" data-index="grossProfit" />
    <a-table-column title="推送时间" data-index="pushTime" />
  </a-table>
</template>
<script>
export default {
  props: {
    dataSource: { type: Array, default: () => [] }
  },
  setup () {
    const rowClass = (record, index) => {
      let rowClassName = ''
      if (index % 2 === 1) {
        rowClassName = 'table-striped'
      }
      if (record.status && !record.status.value) {
        rowClassName += ' not-archive'
      }
      return rowClassName
    }
    return {
      rowClass
    }
  }
}
</script>
<style lang="less" scoped>
// @import './index.less';
</style>
